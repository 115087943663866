import React from "react";
import PropTypes from "prop-types";

// MUI
import Button from "components/MaterialKit/MKButton";
import { Stack } from "@mui/material";

const Submit = ({ buttonLabel, onSubmit }) => {
  return (
    <Stack
      flexWrap="wrap"
      flexDirection="row"
      width={1.0}
      justifyContent="center"
      style={{ marginTop: "40px" }}
    >
      <Button onClick={() => onSubmit()} color="primary" circular>
        {buttonLabel}
      </Button>
    </Stack>
  );
};

Submit.propTypes = {
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

Submit.defaultProps = {
  buttonLabel: "Submit",
};

export default Submit;

import { useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// MUI
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MKBox from "components/MaterialKit/MKBox";
import MKTypography from "components/MaterialKit/MKTypography";

const Table101 = ({ columns, rows }) => {
  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <MKBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
        })}
      >
        REFLECTIONS FROM OUR COMMUNITY
      </MKBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      const template = (
        <MKBox
          key={uuidv4()}
          component="td"
          p={1.5}
          textAlign={align}
          sx={({ borders: { borderWidth, borderColor } }) => ({
            borderBottom: `${borderWidth[1]} solid ${borderColor}`,
          })}
        >
          <MKTypography
            variant="button"
            sx={{
              display: "inline-block",
              whiteSpace: "pre-line",
              fontSize: { xs: "14px", lg: "16px", xl: "18px" },
            }}
          >
            {row[name]}
          </MKTypography>
        </MKBox>
      );

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <MKBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </MKBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
};

// Setting default values for the props of Table
Table101.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table101.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table101;

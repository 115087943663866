import React from "react";
import PropTypes from "prop-types";

// MUI
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";

const EnterNote = ({ setInputText, inputLabel, inputText }) => {
  return (
    <Box onChange={(e) => setInputText(e.target.value)} style={{ marginTop: "40px" }}>
      <Input type="inputText" label={inputLabel} fullWidth multiline value={inputText} />
    </Box>
  );
};

EnterNote.propTypes = {
  setInputText: PropTypes.func,
  inputLabel: PropTypes.string,
  inputText: PropTypes.string,
};

EnterNote.defaultProps = {
  setInputText: null,
  inputLabel: "",
  inputText: "",
};

export default EnterNote;

import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMediaQuery } from "@react-hook/media-query";

import Table101 from "./Table101";

const Chart101 = ({ setShowElephant, idea, auth }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px)");
  const mdSize = useMediaQuery("only screen and (min-width: 768px)");
  const svgRef = useRef(null);
  let myStarIndex = -1;

  const [avgX, setAvgX] = useState(0);
  const [count, setCount] = useState(0);
  const [stdevX, setStdevX] = useState(0);
  const [sharpeX, setSharpeX] = useState(0);

  const [selected, setSelected] = useState([]);

  const standardDeviation = (arr) => {
    const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
    return Math.sqrt(
      arr
        .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
        .reduce((acc, val) => acc + val, 0) /
        (arr.length - 1)
    );
  };

  const avg = (arr) => {
    return arr.reduce((acc, val) => acc + val, 0) / arr.length;
  };

  useEffect(() => {
    const data = [];
    const dataX = [];
    let index = 0;
    idea.scales.forEach((node) => {
      const obj = {};
      const confidence = node.confidence === null ? 0.5 : node.confidence; // eslint-disable-line prefer-destructuring
      const authorIntent = node.authorIntent; // eslint-disable-line prefer-destructuring

      obj.note = node.note
        ? node.note
        : node.ideaCreated && node.ideaCreated.myPoint && node.ideaCreated.myPoint.length > 0
        ? node.ideaCreated.myPoint
        : "";
      obj.x = authorIntent > 0 ? confidence : authorIntent < 0 ? -confidence : 0;
      obj.y = 0;
      data.push(obj);
      dataX.push(authorIntent > 0 ? confidence : authorIntent < 0 ? -confidence : 0);

      if (
        auth &&
        auth.user &&
        auth.user._id &&
        node.user &&
        node.user._id &&
        auth.user._id === node.user._id
      ) {
        myStarIndex = index;
      }
      index += 1;
    });
    setCount(data.length);
    setAvgX(avg(dataX));
    setStdevX(standardDeviation(dataX));
    setSharpeX(standardDeviation(dataX) !== 0 ? avg(dataX) / standardDeviation(dataX) : 0);
    if (myStarIndex < 0) {
      setShowElephant(false);
    }

    // Set the dimensions and margins of the graph
    const innerWidth = mdSize ? 595 : smSize ? 395 : 295;
    const innerHeight = 145; // Height = 200px
    const extraHeightBottom = 25; // 20 pixels are added on bottom so as not to chop off x-axis label
    const margin = { top: 15, right: 15, bottom: 15, left: 15 };
    const width = innerWidth + margin.left + margin.right; // 395 + 15 + 15
    const height = innerHeight + margin.top + margin.bottom + extraHeightBottom; // 145 + 15 + 15 + 25

    const svgElement = d3.select(svgRef.current);
    svgElement.selectAll("*").remove(); // Clear svg content before adding new elements

    const svg = svgElement
      .attr("width", width)
      .attr("height", height)
      .attr("transform", `translate(${margin.left}, ${margin.bottom})`);

    // Y Axis
    const y = d3.scaleLinear().domain([-1.15, 1.15]).range([innerHeight, 0]); // axis height
    svg
      .append("g")
      .attr("transform", `translate(${innerWidth / 2},0)`)
      .call(d3.axisLeft(y).tickValues([]).tickSizeOuter(0));

    // X Axis
    const x = d3.scaleLinear().domain([-1.15, 1.15]).range([0, innerWidth]); // axis width
    svg
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(x).tickSizeOuter(0).ticks(5));
    svg
      .append("text")
      .attr(
        "transform",
        `translate(${(mdSize ? -130 : smSize ? -85 : -45) + innerWidth / 2}, ${height - 5})`
      )
      .style("text-anchor", "middle")
      .text("Disagree")
      .attr("font-size", "16");

    svg
      .append("text")
      .attr(
        "transform",
        `translate(${(mdSize ? 130 : smSize ? 85 : 45) + innerWidth / 2}, ${height - 5})`
      )
      .style("text-anchor", "middle")
      .text("Agree")
      .attr("font-size", "16");

    const densityData = d3
      .contourDensity()
      .y((d) => {
        return y(d.y);
      })
      .x((d) => {
        return x(d.x);
      })
      .size([width, height])
      .bandwidth(smSize ? 30 : 20)(data);

    // Prepare a color palette
    const color = d3
      .scaleLinear()
      .domain([0, 0.65 * Math.max(...densityData.map((o) => o.value))]) // 0.65 provides darker colors on top (1.0 represenst max)
      .range(["white", "#0074F4"]);

    // Draw density image
    svg
      .selectAll("path")
      .data(densityData)
      .enter()
      .append("path")
      .attr("d", d3.geoPath())
      .attr("fill", (d) => {
        return color(d.value);
      });

    // Community member dots
    for (let j = 0; j < data.length; j += 1) {
      const Xpx = ((data[j].x + 1.15) / 2.3) * innerWidth;
      const Ypx = ((-data[j].y + 1.15) / 2.3) * innerHeight;

      let bMsg = false;
      if (data[j].note && data[j].note.length > 0) {
        bMsg = true;
      }

      if (myStarIndex !== j) {
        svg
          .append("circle")
          .attr("cx", Xpx)
          .attr("cy", Ypx)
          .attr("r", 3)
          // Extra space around the dot that is still active
          .attr("stroke-width", 1)
          .attr("stroke", bMsg ? "invisible" : "black")
          .style("fill", bMsg > 0 ? "black" : "white");
      }
    }

    // My star
    if (myStarIndex >= 0) {
      const Xpx = ((data[myStarIndex].x + 1.15) / 2.3) * innerWidth;
      const Ypx = ((-data[myStarIndex].y + 1.15) / 2.3) * innerHeight;

      const sym = d3.symbol().type(d3.symbolStar).size(400);
      d3.select(svgRef.current)
        .append("path")
        .attr("d", sym)
        .attr("fill", "yellow")
        .attr("transform", `translate(${Xpx}, ${Ypx})`)
        .attr("stroke-width", 1)
        .attr("stroke", "black");
    }

    const goBrush = (e) => {
      const rows = [];
      for (let j = 0; j < data.length; j += 1) {
        const Xpx = ((data[j].x + 1.15) / 2.3) * innerWidth;
        const Ypx = ((-data[j].y + 1.15) / 2.3) * innerHeight;

        if (
          Xpx >= e.selection[0][0] &&
          Xpx <= e.selection[1][0] &&
          Ypx >= e.selection[0][1] &&
          Ypx <= e.selection[1][1]
        ) {
          // An empty line
          const obj = {
            notes: "-",
          };
          let foundText = false;

          // Notes
          if (data[j].note && data[j].note.length > 0) {
            foundText = true;
            obj.notes = <i>{data[j].note}</i>;
          }
          if (foundText) {
            rows.push(obj);
          }
        }
      }
      setSelected(rows);
    };

    // Pause for half second before generating table
    let timer = null;
    const handleBrush = (e) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        goBrush(e);
      }, 500);
    };
    svg.append("g").call(d3.brush().on("brush", handleBrush));
  }, [smSize, mdSize]);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        {count >= 4 && (
          <>
            <div>
              {sharpeX < 0.15 && sharpeX > -0.15 && <p>Lack of consensus in our community.</p>}
              {sharpeX >= 0.15 && sharpeX < 0.35 && (
                <p>Overall, slightly more community agreement than disagreement.</p>
              )}
              {sharpeX >= 0.35 && sharpeX < 0.65 && (
                <p>More community agreement than disagreement.</p>
              )}
              {sharpeX >= 0.65 && sharpeX < 1 && (
                <p>Much more community agreement than disagreement.</p>
              )}
              {sharpeX >= 1 && <p>Clear community agreement.</p>}
              {sharpeX <= -0.15 && sharpeX > -0.35 && (
                <p>Overall, slightly more community disagreement than agreement.</p>
              )}
              {sharpeX <= -0.35 && sharpeX > -0.65 && (
                <p>More community disagreement than agreement.</p>
              )}
              {sharpeX <= -0.65 && sharpeX > -1 && (
                <p>Much more community disagreement than agreement.</p>
              )}
              {sharpeX <= -1 && <p>Clear community disagreement.</p>}
            </div>
            <div>{stdevX - Math.abs(avgX) > 0.4 && <p>Wide range of responses</p>}</div>
          </>
        )}
        {count < 4 && (
          <p style={{ textAlign: "center" }}>
            Limited community engagement so far. Please check back later.
          </p>
        )}

        <svg ref={svgRef} />
      </div>

      {selected.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <Table101 columns={[{ name: "notes", align: "center" }]} rows={selected} />
        </div>
      )}
    </>
  );
};

Chart101.propTypes = {
  idea: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setShowElephant: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Chart101);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";
import { Radio, Stack } from "@mui/material";

import Spinner from "../layout/Spinner";
import CreateMyContent from "../create/CreateMyContent";
import SliderConfidence from "../slider/SliderConfidence";
import { addIdeaScale } from "../../actions/idea";
import QuestionText from "../styles/QuestionText";
import LabelText from "../styles/LabelText";
import ClickText from "../styles/ClickText";

const InterpretCommunityTopic = ({
  activity,
  setting,
  childUpdated,
  setChildUpdated,
  setInterpretedIntell,
  addIdeaScale,
  idea,
  auth,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    // If submitting
    if (idea.idea && idea.idea.scales && submitting) {
      for (let i = 0; i < idea.idea.scales.length; i += 1) {
        if (
          idea.idea.scales[i].user &&
          idea.idea.scales[i].user._id &&
          JSON.stringify(idea.idea.scales[i].user._id) === JSON.stringify(auth.user._id)
        ) {
          setInterpretedIntell(idea.idea);
        }
      }
    }
  }, [idea.idea]);

  useEffect(() => {
    // first time entering
    if (activity.communityTopic && activity.communityTopic.scales && !submitting) {
      for (let i = 0; i < activity.communityTopic.scales.length; i += 1) {
        if (
          activity.communityTopic.scales[i].user &&
          activity.communityTopic.scales[i].user._id &&
          JSON.stringify(activity.communityTopic.scales[i].user._id) ===
            JSON.stringify(auth.user._id)
        ) {
          setInterpretedIntell(activity.communityTopic);
        }
      }
    }
  }, [activity.communityTopic]);

  const [communityInterpretation, setCommunityInterpretation] = useState(-2); // Typically -1/0/1 (-2 represents uninitialized)
  const [confidence, setConfidence] = useState(-2); // Typically ranges 0->1 (-2 represents uninitialized)
  const [instructions, setInstructions] = useState("");

  // Everytime there is any user interaction, notify parent by updating a counter
  useEffect(() => {
    setChildUpdated(childUpdated + 1);
  }, [communityInterpretation, confidence]);

  const handleCommunityTopicInterpretation = (e) => {
    setCommunityInterpretation(e);
    setConfidence(-2);

    if (e === 0) {
      setInstructions("Please explain why you are unsure about the guiding question...");
    }
    if (e > 0) {
      setInstructions("Please explain why you agree with the guiding question...");
    }
    if (e < 0) {
      setInstructions("Please explain why you disagree with the guiding question...");
    }
  };

  const onSubmit = () => {
    setSubmitting(true);
    addIdeaScale(activity.communityTopic._id, {
      cannotOverwriteExisting: true,
      activityType: 101,
      authorIntent: communityInterpretation,
      confidence: confidence > -2 ? confidence : null,
      note,
    });
    console.log("onSubmit", communityInterpretation, confidence);
  };

  return submitting ? (
    <Spinner />
  ) : (
    <>
      <div style={{ marginTop: "40px", marginBottom: "10px" }}>
        <QuestionText large={communityInterpretation <= -2 ? 1 : 0}>
          Select below what best describes your position.
        </QuestionText>
      </div>

      <div>
        <LabelText>
          <Radio
            checked={communityInterpretation === -1}
            onClick={() => handleCommunityTopicInterpretation(-1)}
          />
          I disagree
        </LabelText>

        <LabelText>
          <Radio
            checked={communityInterpretation === 1}
            onClick={() => handleCommunityTopicInterpretation(1)}
          />
          I agree
        </LabelText>

        <LabelText>
          <Radio
            checked={communityInterpretation === 0}
            onClick={() => handleCommunityTopicInterpretation(0)}
          />
          I am unsure
        </LabelText>
      </div>

      {/* How confident? */}
      {(communityInterpretation === 1 || communityInterpretation === -1) && (
        <>
          <div style={{ marginTop: "20px", marginBottom: "15px", textAlign: "center" }}>
            <QuestionText large={confidence <= -2 ? 1 : 0}>
              How confident are you in your position?
            </QuestionText>
            {confidence <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
          </div>

          <SliderConfidence userInitLevel={confidence} updated={setConfidence} />
        </>
      )}

      {communityInterpretation > -2 && (confidence > -2 || communityInterpretation === 0) && (
        <>
          {activity.afterOptionAddNewIntellAfter && (
            <div style={{ marginTop: "50px" }}>
              {(communityInterpretation === 0 ||
                (communityInterpretation > -2 && confidence > -2)) && (
                <>
                  {activity.instructions1 && activity.instructions1.length > 0 && (
                    <QuestionText style={{ marginBottom: "10px" }}>
                      {activity.instructions1}
                    </QuestionText>
                  )}

                  <QuestionText style={{ marginBottom: "10px" }}>{instructions}</QuestionText>

                  {activity.newInTellSelfEvaluate && (
                    <CreateMyContent
                      instructions={instructions}
                      initSelection={1}
                      activity={activity}
                      setting={setting}
                      requestSelfEval={activity.newInTellSelfEvaluate}
                      addIdeaScale={{
                        id: activity.communityTopic._id,
                        authorIntent: communityInterpretation,
                        confidence: confidence > -2 ? confidence : null,
                        activityType: 101,
                      }}
                      auth={auth}
                      notifyParent={childUpdated}
                      setNotifyParent={setChildUpdated}
                    />
                  )}

                  {!activity.newInTellSelfEvaluate && (
                    <>
                      <Box onChange={(e) => setNote(e.target.value)} style={{ marginTop: "40px" }}>
                        <Input
                          type="note"
                          label="Your thoughts? (OPTIONAL)"
                          fullWidth
                          value={note}
                        />
                      </Box>

                      <Stack
                        flexWrap="wrap"
                        flexDirection="row"
                        width={1.0}
                        justifyContent="center"
                        style={{ marginTop: "40px" }}
                      >
                        <Button onClick={() => onSubmit()} color="primary" circular>
                          Submit
                        </Button>
                      </Stack>
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {!activity.afterOptionAddNewIntellAfter &&
            communityInterpretation > -2 &&
            (confidence > -2 || communityInterpretation === 0) && (
              <Stack
                flexWrap="wrap"
                flexDirection="row"
                width={1.0}
                justifyContent="center"
                style={{ marginTop: "40px" }}
              >
                <Button onClick={() => onSubmit()} color="primary" circular>
                  Submit
                </Button>
              </Stack>
            )}
        </>
      )}
    </>
  );
};

InterpretCommunityTopic.propTypes = {
  childUpdated: PropTypes.number.isRequired,
  setChildUpdated: PropTypes.func.isRequired,
  activity: PropTypes.oneOfType([PropTypes.object]),
  setting: PropTypes.oneOfType([PropTypes.object]),
};

InterpretCommunityTopic.defaultProps = {
  activity: null,
  setting: null,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  auth: state.auth,
});

export default connect(mapStateToProps, { addIdeaScale })(InterpretCommunityTopic);

import { useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// MUI
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MKBox from "components/MaterialKit/MKBox";
import MKAvatar from "components/MaterialKit/MKAvatar";
import MKTypography from "components/MaterialKit/MKTypography";
import Button from "components/MaterialKit/MKButton";

const Table102 = ({ columns, rows, setSelectedIndex }) => {
  const renderColumns = columns.map(({ name, align }) => {
    return (
      <MKBox
        key={name}
        component="th"
        width="auto"
        pt={1.5}
        pb={1.25}
        textAlign={align}
        pl={1}
        pr={1}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
        })}
      >
        {name.toUpperCase()}
      </MKBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name }) => {
      const template = (
        <MKBox
          key={uuidv4()}
          component="td"
          p={0.5}
          sx={({ borders: { borderWidth, borderColor } }) => ({
            borderBottom: `${borderWidth[1]} solid ${borderColor}`,
          })}
        >
          <MKBox display="flex" alignItems="center" py={0.5} px={0.5}>
            {typeof row[name].icon !== "undefined" &&
              row[name].icon.length > 0 &&
              !(row[name].iconIndex >= 0) && (
                <MKBox mr={1}>
                  <MKAvatar src={row[name].icon} name={row[name].icon} variant="square" size="sm" />
                </MKBox>
              )}

            {typeof row[name].icon !== "undefined" &&
              row[name].icon.length > 0 &&
              row[name].iconIndex >= 0 && (
                <Button
                  onClick={() => setSelectedIndex(row[name].iconIndex)}
                  style={{ marginRight: "5px" }}
                  color="primary"
                  iconOnly
                  size="small"
                >
                  <MKAvatar src={row[name].icon} name={row[name].icon} variant="square" size="xs" />
                </Button>
              )}
            {typeof row[name].text !== "undefined" && (
              <MKTypography
                variant="button"
                fontWeight="medium"
                sx={{
                  width: row[name].textWidth,
                  fontSize: { xs: "10px", lg: "12px", xl: "14px" },
                  whiteSpace: "pre-line",
                }}
              >
                {row[name].text}
              </MKTypography>
            )}
          </MKBox>
        </MKBox>
      );

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <MKBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </MKBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
};

// Setting default values for the props of Table
Table102.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table102.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  setSelectedIndex: PropTypes.PropTypes.func.isRequired,
};

export default Table102;

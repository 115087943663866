module.exports = Object.freeze({
  MY_DOMAIN: "https://www.insight.click",
  USE_BETA_LIST: true,
  REGISTER_USER_NAME: false,
  EDUCATION_BUILD: true,
  EDUCATION_ANONYMOUS: false,
  EDUCATION_ADMIN: "kooznet@yahoo.com",
});
// MY_DOMAIN: "https://www.insight.click",
// MY_DOMAIN: "http://localhost:3000",
// MY_DOMAIN: "http://10.0.0.80:3000",

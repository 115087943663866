import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import { Container } from "@mui/material";

import Spinner from "../layout/Spinner";
import Chart102 from "../chart/Chart102";
import IdeaDetail from "../idea/IdeaDetail";
import NavbarTop from "../navbar/NavbarTop";
import InterpretIntell from "../meaning/InterpretIntell";
import { modifyActivity } from "../../actions/activity";

const Interpret102 = ({ activity, setting, numericalLabel, modifyActivity, auth }) => {
  const [cardHeight, setCardHeight] = useState(0);
  const [childUpdated, setChildUpdated] = useState(0);
  const divRef = useRef(null);

  const [interpretedIntell, setInterpretedIntell] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = () => {
    setSubmitting(true);
    modifyActivity({
      activityID: activity._id,
      studentIDCompleted: auth.user._id,
    });
  };

  useEffect(() => {
    if (childUpdated > 1) {
      setTimeout(() => {
        if (divRef && divRef.current && divRef.current.scrollIntoView) {
          divRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setTimeout(() => {
          if (divRef && divRef.current && divRef.current.scrollIntoView) {
            divRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 500);
      }, 500);
    }
  }, [childUpdated, interpretedIntell]);

  return submitting ? (
    <Spinner />
  ) : (
    <>
      <NavbarTop
        title={`ACTIVITY #${numericalLabel}`}
        content1="Review the following inTell and answer the questions below."
        content2={activity.instructions102}
      />

      <Container
        sx={{
          position: cardHeight < 250 ? "sticky" : "",
          top: 0,
          zIndex: 10,
          mt: { xs: 8, sm: 11 },
        }}
        maxWidth="sm"
      >
        <Box sx={{ width: "100%" }}>
          <IdeaDetail
            idea={activity.inTellExisting}
            hrefHiddenExternal={activity.inTellExisting.isVideo}
            hrefHiddenInternal
            greyOut
            showExtended
            setCardHeight={setCardHeight}
          />
        </Box>
      </Container>

      <Container maxWidth="sm" sx={{ mt: 2, mb: 12 }}>
        <>
          {!interpretedIntell && (
            <InterpretIntell
              activity={activity}
              setting={setting}
              childUpdated={childUpdated}
              setChildUpdated={setChildUpdated}
              setInterpretedIntell={setInterpretedIntell}
            />
          )}
          {interpretedIntell && (
            <>
              <div style={{ marginTop: "40px" }}>
                <Chart102 setShowElephant={onSubmit} idea={interpretedIntell} />
              </div>
              <div style={{ marginTop: "60px", marginBottom: "40px", textAlign: "center" }}>
                <Button onClick={() => onSubmit()} color="primary" circular>
                  Continue
                </Button>
              </div>
            </>
          )}
        </>
      </Container>
      <div ref={divRef} />
    </>
  );
};

Interpret102.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  numericalLabel: PropTypes.number.isRequired,
  setting: PropTypes.oneOfType([PropTypes.object]),
};

Interpret102.defaultProps = {
  setting: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { modifyActivity })(Interpret102);

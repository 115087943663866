import React from "react";
import PropTypes from "prop-types";

// MUI
import Box from "components/MaterialKit/MKBox";
import Input from "components/MaterialKit/MKInput";

import QuestionText from "../styles/QuestionText";
import YesNoRadio from "./YesNoRadio";
import ExpertiseRadio from "./ExpertiseRadio";

const Expertise = ({
  isBetterUnderstanding,
  setIsBetterUnderstanding,
  confidence,
  setConfidence,
  myExpertise,
  setMyExpertise,
  answerQuestions,
  setAnswerQuestions,
  publicEngagement,
  setPublicEngagement,
  tookSide,
  setTookSide,
  otherSide,
  setOtherSide,
  solution,
  setSolution,
  wouldChange,
  setWouldChange,
  haveChanged,
  setHaveChanged,
  changed,
  setChanged,
}) => {
  return (
    <>
      <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
        <QuestionText large={isBetterUnderstanding <= -2 ? 1 : 0}>
          I feel I have a more encompassing and realistic understanding than most people in our
          community of this topic, and I am mindful of different perspectives.
        </QuestionText>
      </div>
      <YesNoRadio
        radioStatus={isBetterUnderstanding}
        setRadioStatus={setIsBetterUnderstanding}
        showNotSure
      />

      {isBetterUnderstanding === 1 && (
        <>
          <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
            <QuestionText large={confidence <= 1 ? 1 : 0}>
              When it comes to this topic, I would say...
            </QuestionText>
          </div>
          <ExpertiseRadio radioStatus={confidence} setRadioStatus={setConfidence} />

          {confidence > 1 && (
            <>
              <Box onChange={(e) => setMyExpertise(e.target.value)} style={{ marginTop: "40px" }}>
                <Input
                  type="myExpertise"
                  label="Describe how you acquired your expertise on this topic (OPTIONAL, use as many words as you wish)"
                  fullWidth
                  multiline
                  value={myExpertise}
                />
              </Box>

              <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
                <QuestionText large={tookSide <= -2 ? 1 : 0}>
                  My perspective clashes with the perspectives of others.
                </QuestionText>
                <YesNoRadio radioStatus={tookSide} setRadioStatus={setTookSide} showNotSure />
              </div>

              {tookSide === 1 && (
                <>
                  <Box onChange={(e) => setOtherSide(e.target.value)} style={{ marginTop: "40px" }}>
                    <Input
                      type="otherSide"
                      label="Explain the strongest and most valid point(s) of other perspectives that differ significantly from your own."
                      fullWidth
                      multiline
                      value={otherSide}
                    />
                  </Box>

                  {otherSide.length > 2 && (
                    <>
                      <Box
                        onChange={(e) => setSolution(e.target.value)}
                        style={{ marginTop: "40px" }}
                      >
                        <Input
                          type="solution"
                          label="What's the most effective way to rise above the polarization surrounding this issue? (OPTIONAL)"
                          fullWidth
                          multiline
                          value={solution}
                        />
                      </Box>

                      <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
                        <QuestionText large={changed <= -2 ? 1 : 0}>
                          Has your position on this topic ever significantly changed from what it is
                          now?
                        </QuestionText>
                        <YesNoRadio radioStatus={changed} setRadioStatus={setChanged} />
                      </div>

                      {changed === -1 && (
                        <Box
                          onChange={(e) => setWouldChange(e.target.value)}
                          style={{ marginTop: "40px" }}
                        >
                          <Input
                            type="wouldChange"
                            label="I would change my position if... (OPTIONAL)"
                            fullWidth
                            multiline
                            value={wouldChange}
                          />
                        </Box>
                      )}
                      {changed === 1 && (
                        <Box
                          onChange={(e) => setHaveChanged(e.target.value)}
                          style={{ marginTop: "40px" }}
                        >
                          <Input
                            type="haveChanged"
                            label="I changed my position when... (OPTIONAL)"
                            fullWidth
                            multiline
                            value={haveChanged}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}

              {(tookSide === -1 ||
                tookSide === 0 ||
                (tookSide === 1 && otherSide.length > 2 && changed > -2)) && (
                <>
                  <div style={{ marginTop: "20px", marginBottom: "10px", textAlign: "center" }}>
                    <QuestionText large={answerQuestions <= -2 ? 1 : 0}>
                      Would you be willing to answer questions from other members of our inSight
                      community?
                    </QuestionText>
                    <YesNoRadio
                      radioStatus={answerQuestions}
                      setRadioStatus={setAnswerQuestions}
                      showNotSure
                    />
                  </div>

                  {answerQuestions > -2 && (
                    <div style={{ marginTop: "20px", marginBottom: "10px", textAlign: "center" }}>
                      <QuestionText large={publicEngagement <= -2 ? 1 : 0}>
                        Would you be open to engaging in a face-to-face panel discussion with others
                        who consider themselves equally knowledgeable on this topic?
                      </QuestionText>
                      <YesNoRadio
                        radioStatus={publicEngagement}
                        setRadioStatus={setPublicEngagement}
                        showNotSure
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Expertise.propTypes = {
  isBetterUnderstanding: PropTypes.number.isRequired,
  setIsBetterUnderstanding: PropTypes.func.isRequired,
  confidence: PropTypes.number.isRequired,
  setConfidence: PropTypes.func.isRequired,
  myExpertise: PropTypes.string.isRequired,
  setMyExpertise: PropTypes.func.isRequired,
  answerQuestions: PropTypes.number.isRequired,
  setAnswerQuestions: PropTypes.func.isRequired,
  publicEngagement: PropTypes.number.isRequired,
  setPublicEngagement: PropTypes.func.isRequired,
  tookSide: PropTypes.number.isRequired,
  setTookSide: PropTypes.func.isRequired,
  otherSide: PropTypes.string.isRequired,
  setOtherSide: PropTypes.func.isRequired,
  solution: PropTypes.string.isRequired,
  setSolution: PropTypes.func.isRequired,
  wouldChange: PropTypes.string.isRequired,
  setWouldChange: PropTypes.func.isRequired,
  haveChanged: PropTypes.string.isRequired,
  setHaveChanged: PropTypes.func.isRequired,
  changed: PropTypes.number.isRequired,
  setChanged: PropTypes.func.isRequired,
};

export default Expertise;

import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import Box from "components/MaterialKit/MKBox";
import { Radio } from "@mui/material";
import { Help } from "@mui/icons-material";

import DialogMessage from "../dialog/DialogMessage";
import LabelText from "../styles/LabelText";

// radioStatus: <=1 = Undefined, 2 = Reliable Source, 3 = Guid, 4 = Expert
const ExpertiseRadio = ({ radioStatus, setRadioStatus }) => {
  // DIALOGS
  const [reliableSourceDialog, setReliableSourceDialog] = useState(false);
  const [guideDialog, setGuideDialog] = useState(false);
  const [expertDialog, setExpertDialog] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <div style={{ textAlign: "left" }}>
          <div style={{ marginBottom: "10px" }}>
            <LabelText>
              <Radio
                checked={radioStatus === 2}
                onClick={() => setRadioStatus(2)}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              />
              I have ABOVE-AVERAGE understanding
              <Help
                onClick={() => setReliableSourceDialog(true)}
                color="primary"
                style={{ marginLeft: "3px", marginBottom: "3px" }}
              />
            </LabelText>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <LabelText>
              <Radio
                checked={radioStatus === 3}
                onClick={() => setRadioStatus(3)}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              />
              I am VERY KNOWLEDGEABLE
              <Help
                onClick={() => setGuideDialog(true)}
                color="primary"
                style={{ marginLeft: "3px", marginBottom: "3px" }}
              />
            </LabelText>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <LabelText>
              <Radio
                checked={radioStatus === 4}
                onClick={() => setRadioStatus(4)}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              />
              I am an EXPERT
              <Help
                onClick={() => setExpertDialog(true)}
                color="primary"
                style={{ marginLeft: "3px", marginBottom: "3px" }}
              />
            </LabelText>
          </div>
        </div>
      </Box>

      {reliableSourceDialog && (
        <DialogMessage reliableSource handleDialogClose={() => setReliableSourceDialog(false)} />
      )}
      {guideDialog && <DialogMessage guide handleDialogClose={() => setGuideDialog(false)} />}
      {expertDialog && <DialogMessage expert handleDialogClose={() => setExpertDialog(false)} />}
    </>
  );
};

ExpertiseRadio.propTypes = {
  radioStatus: PropTypes.number.isRequired,
  setRadioStatus: PropTypes.func.isRequired,
};

export default ExpertiseRadio;

import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Typography from "components/MaterialKit/MKTypography";

const DialogMessage = ({
  terms,
  potie,
  reliableSource,
  guide,
  expert,
  goodFaith,
  intent,
  deepDive,
  text,
  url,
  agent,
  slider,
  check,
  select,
  intentions,
  frameContext,
  frameResolution,
  myConcern,
  errorCorrection,
  criticContent,
  criticConcern,
  proxy,
  players,
  communityTopic,
  values,
  handleDialogClose,
}) => {
  return (
    <Dialog open>
      <DialogContent style={{ padding: "30px", paddingTop: "20px", paddingBottom: "20px" }}>
        <>
          {terms && (
            <>
              <Typography style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>
                TERMS OF USE
              </Typography>

              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                <i>Effective Date: June 1, 2023</i>
              </Typography>

              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                Thank you for visiting insight.click. This Privacy Terms and Conditions Statement
                (&quot;Statement&quot;) governs the collection, use, and disclosure of personal
                information when you use our website. We are committed to protecting your privacy
                and complying with applicable data protection laws, including the Personal
                Information Protection and Electronic Documents Act (PIPEDA) in Canada, the General
                Data Protection Regulation (GDPR) in Europe, and applicable privacy laws in the
                United States.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>1. Consent</Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                By using our website, you consent to the collection, use, and disclosure of your
                personal information as described in this Statement. If you do not agree with this
                Statement, please do not use our website.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>
                2. Collection of Personal Information
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                We may collect the following types of personal information from you:
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                • Contact and Login Information: Email address and password.
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                • Usage Data: Information about your interactions with our website.
              </Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                • Communication Data: Information that you provide when contacting us through our
                website or other communication channels.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>3. Use of Personal Information</Typography>
              <Typography style={{ fontSize: "10px" }}>
                We use your personal information for the following purposes:
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                • To provide and maintain our website.
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                • To personalize your experience and improve our website.
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                • To communicate with you, respond to your inquiries, and provide customer support.
              </Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                • To comply with legal obligations and protect our rights and interests.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>
                4. Disclosure of Personal Information
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                We may disclose your personal information to third parties in the following
                circumstances:
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                • Service Providers: We may engage trusted third-party service providers to assist
                us in operating our website and performing related services. These service providers
                will have access to your personal information only to the extent necessary to
                perform their duties.
              </Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                • Legal Compliance: We may disclose your personal information if required by law or
                in response to valid legal requests, such as subpoenas, court orders, or government
                regulations.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>5. Data Security</Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                We implement reasonable security measures to protect your personal information from
                unauthorized access, alteration, disclosure, or destruction. However, please note
                that no method of transmission over the internet or electronic storage is 100%
                secure, and we cannot guarantee absolute security.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>6. Your Rights</Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                You have certain rights regarding your personal information, including the right to
                access, correct, and delete your personal information. You also have the right to
                withdraw your consent for the processing of your personal information. To activity
                these rights, please contact us using the contact information provided below.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>
                7. Retention of Personal Information
              </Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                We will retain your personal information for as long as necessary to fulfill the
                purposes outlined in this Statement unless a longer retention period is required by
                law.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>8. Third-Party Websites</Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                Our website may contain links to third-party websites that are not operated by us.
                We are not responsible for the privacy practices of these websites. We recommend
                reviewing the privacy policies of third-party websites before providing any personal
                information.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>9. Updates to this Statement</Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                We may update this Statement from time to time to reflect changes in our practices
                or legal obligations. We will post the updated version on our website and indicate
                the effective date. Your continued use of our website after the changes constitute
                your acceptance of the updated Statement.
              </Typography>

              <Typography style={{ fontSize: "10px" }}>10. Contact Us</Typography>
              <Typography style={{ fontSize: "10px", marginBottom: "10px" }}>
                If you have any questions or concerns about this Statement or our privacy practices,
                please contact us at: spark@insight.click
              </Typography>
            </>
          )}
          {potie && (
            <>
              <Typography style={{ fontSize: "18px" }}>POTIE violations include:</Typography>
              <Typography style={{ fontSize: "18px" }}>
                • Promoting hate, violence, or suffering.
              </Typography>
              <Typography style={{ fontSize: "18px" }}>
                • Attacking a person’s character (or dehumanizing) rather than debating the issue.
              </Typography>
              <Typography style={{ fontSize: "18px" }}>
                • Claiming a moral high ground at the expense of others (virtue signalling).
              </Typography>
              <Typography style={{ fontSize: "18px" }}>
                • Criticizing someone for arguments that they never made (straw man).
              </Typography>
            </>
          )}
          {reliableSource && (
            <Typography style={{ fontSize: "18px" }}>
              With friends and family, I am comfortable leading discussions on this topic and
              offering advice.
            </Typography>
          )}
          {guide && (
            <Typography style={{ fontSize: "18px" }}>
              While I&apos;m not an expert (yet), I&apos;ve immersed myself in this topic. I believe
              I could make valuable contributions to a community of knowledgeable individuals.
            </Typography>
          )}
          {expert && (
            <Typography style={{ fontSize: "18px" }}>
              I believe I could deliver an insightful and nuanced presentation on this topic to an
              expert community, navigate this topic&apos;s complexities, and confidently answer
              challenging questions. In a room of experts, I could hold my own.
            </Typography>
          )}

          {goodFaith && (
            <>
              <Typography style={{ fontSize: "18px" }}>
                Mistakes are common and when made in good faith, forgivable. On the other hand,
                purposeful deception is no accident and is harmful to the common good.
              </Typography>
              <Typography style={{ fontSize: "18px" }}>
                When reality is exposed, responsibility ultimately lies with each of us to
                self-correct and when necesssary, acknowledge deceptions. Failure to do so
                perpetuates harm.
              </Typography>
            </>
          )}
          {intent && (
            <Typography style={{ fontSize: "18px" }}>
              We strive to be encompassing, realistic, and mindful of different perspectives.
            </Typography>
          )}
          {deepDive && (
            <Typography style={{ fontSize: "18px" }}>
              I understand this issue beyond the headlines and seek to fairly take into account
              different perspectives, especially those I disagree with.
            </Typography>
          )}

          {text && (
            <Typography style={{ fontSize: "18px" }}>
              To submit, you must first fill in the text field.
            </Typography>
          )}
          {url && (
            <>
              <Typography style={{ fontSize: "18px" }}>
                To submit, you must first submit a valid URL.
              </Typography>
              <Typography variant="caption" style={{ fontSize: "18px" }}>
                If the problem persists, check your network connection.
              </Typography>
            </>
          )}
          {agent && (
            <Typography style={{ fontSize: "18px" }}>
              To submit, you must first select a waveMaker.
            </Typography>
          )}
          {slider && (
            <Typography style={{ fontSize: "18px" }}>
              To submit, you must first select a position on the slider.
            </Typography>
          )}
          {intentions && (
            <Typography style={{ fontSize: "18px" }}>
              This information space honors high quality dialogue and &quot;good intentions&quot;.
              Please confirm your inTell meets the minimum standards before submitting.
            </Typography>
          )}
          {check && (
            <Typography style={{ fontSize: "18px" }}>
              To submit, you must first select the required check box(es).
            </Typography>
          )}
          {select && (
            <Typography style={{ fontSize: "18px" }}>
              To submit, you must make a selection.
            </Typography>
          )}

          {frameContext && (
            <Typography style={{ fontSize: "18px" }}>
              Without appreciating the frame and context, difficulties can arise in understanding
              the core arguments in the inTell. This can cascade into frustration when otherwise
              valid (but irrelevant) counter-points are made.
            </Typography>
          )}
          {frameResolution && (
            <Typography style={{ fontSize: "18px" }}>
              Is there a shared resolution? Is there a better path forward? A compromise?
            </Typography>
          )}
          {myConcern && (
            <Typography style={{ fontSize: "18px" }}>
              Why? Why? Why? What are your motivations? What is driving you? Like the unseen part of
              an iceberg, this is typically invisible, unspoken, and the source of most conflicts.
            </Typography>
          )}
          {errorCorrection && (
            <Typography style={{ fontSize: "18px" }}>
              Our knowing is always evolving and self-correction is an essential transformational
              force. What is the line in the sand that when crossed, you will admit you were wrong?
            </Typography>
          )}
          {criticContent && (
            <Typography style={{ fontSize: "18px" }}>
              Think carefully about the positions of your most competent critics...
            </Typography>
          )}
          {criticConcern && (
            <Typography style={{ fontSize: "18px" }}>
              Why? Why? Why? What are their motivations? What is driving them? Like the unseen part
              of an iceberg, this is typically invisible, unspoken, and the source of most
              conflicts.
            </Typography>
          )}
          {proxy && (
            <Typography style={{ fontSize: "16px" }}>
              Our understanding of reality is a shared co-creation of what we learn from others and
              what we each overlay in self-reflection. Few can appreciate what it means to climb Mt.
              Everest, yet most can put together a sense of vast snow-covered peaks from the many
              prior first-hand experiences of others. As our understanding of the world rests to a
              large degree on what others say, choose those sources carefully.
            </Typography>
          )}
          {players && (
            <Typography style={{ fontSize: "18px" }}>
              Every situation has many participants and they are integral to almost every story.
              Their motivations and actions ultimately shape our understanding of the world.
            </Typography>
          )}
          {communityTopic && (
            <Typography style={{ fontSize: "18px" }}>
              To submit, you must first select a community topic.
            </Typography>
          )}
          {values && (
            <Typography style={{ fontSize: "18px" }}>
              To submit, you must first select one of the values.
            </Typography>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Stack
          flexWrap="wrap"
          justifyContent="center"
          flexDirection="row"
          width={1.0}
          style={{ marginBottom: "10px" }}
        >
          <Button onClick={() => handleDialogClose()} color="primary" circular>
            OK
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

DialogMessage.propTypes = {
  terms: PropTypes.bool,
  potie: PropTypes.bool,
  reliableSource: PropTypes.bool,
  guide: PropTypes.bool,
  expert: PropTypes.bool,
  goodFaith: PropTypes.bool,
  intent: PropTypes.bool,
  deepDive: PropTypes.bool,
  text: PropTypes.bool,
  url: PropTypes.bool,
  agent: PropTypes.bool,
  slider: PropTypes.bool,
  check: PropTypes.bool,
  select: PropTypes.bool,
  intentions: PropTypes.bool,
  frameContext: PropTypes.bool,
  frameResolution: PropTypes.bool,
  myConcern: PropTypes.bool,
  errorCorrection: PropTypes.bool,
  criticContent: PropTypes.bool,
  criticConcern: PropTypes.bool,
  proxy: PropTypes.bool,
  players: PropTypes.bool,
  communityTopic: PropTypes.bool,
  values: PropTypes.bool,
  handleDialogClose: PropTypes.func.isRequired,
};

DialogMessage.defaultProps = {
  terms: false,
  potie: false,
  reliableSource: false,
  guide: false,
  expert: false,
  goodFaith: false,
  intent: false,
  deepDive: false,
  text: false,
  url: false,
  agent: false,
  slider: false,
  check: false,
  select: false,
  intentions: false,
  frameContext: false,
  frameResolution: false,
  myConcern: false,
  errorCorrection: false,
  criticContent: false,
  criticConcern: false,
  proxy: false,
  players: false,
  communityTopic: false,
  values: false,
};

export default DialogMessage;

import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import Typography from "components/MaterialKit/MKTypography";

import happyImage from "assets/images/slider/Light1.png";
import madImage from "assets/images/slider/Dark1.png";

import Slider from "./Slider";

const SliderEmotion = ({ userInitLevel, updated }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px");
  const mdSize = useMediaQuery("only screen and (min-width: 900px)");

  const marks = [
    {
      value: -1,
      label: (
        <div style={{ marginTop: mdSize ? "-90px" : "-80px", marginRight: "-110px" }}>
          <img src={madImage} style={{ width: mdSize ? "60px" : "50px" }} alt="..." />
          <div style={{ marginTop: "20px" }}>
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px" } }}>Deeply</Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px" } }}>disturbs me</Typography>
          </div>
        </div>
      ),
    },
    {
      value: 1,
      label: (
        <div
          style={{ marginTop: mdSize ? "-90px" : "-80px", marginLeft: mdSize ? "-55px" : "-45px" }}
        >
          <img src={happyImage} style={{ width: mdSize ? "60px" : "50px" }} alt="..." />
          <div style={{ marginTop: "20px" }}>
            <Typography
              sx={{ fontSize: { xs: "10px", sm: "12px" }, marginLeft: { xs: "2px", sm: "0px" } }}
            >
              Profoundly
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "10px", sm: "12px" },
                marginLeft: { xs: "-30px", sm: "-38px" },
              }}
            >
              resonates with me
            </Typography>
          </div>
        </div>
      ),
    },
  ];

  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  // Increments: 0.01
  // Min: -1 (image label)
  // Max: 1 (image label)

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < -1 ? 0 : smSize ? 1 : 0.7}
        step={0.01}
        aria-label="Slider"
        value={userInitLevel}
        min={-1}
        max={1}
        onChange={onSliderChange}
        marks={marks}
        disabled={!updated}
      />
    </div>
  );
};

SliderEmotion.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

SliderEmotion.defaultProps = {
  updated: null,
};

export default SliderEmotion;

import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import Typography from "components/MaterialKit/MKTypography";

import Slider from "./Slider";

const SliderConfidence = ({ userInitLevel, updated }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px)");
  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  const SliderConfidenceMarks = [
    {
      value: 0,
      label: (
        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, marginRight: "-52px" }}>
          Somewhat
        </Typography>
      ),
    },
    {
      value: 1,
      label: (
        <Typography
          sx={{ fontSize: { xs: "10px", sm: "12px" }, marginLeft: { xs: "-17px", sm: "-22px" } }}
        >
          Very
        </Typography>
      ),
    },
  ];

  return (
    // Increments: 0.01
    // Min: 0 (text label)
    // Max: 1 (text label)
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < 0 ? 0 : smSize ? 1 : 0.7}
        step={0.01}
        aria-label="Slider"
        value={userInitLevel}
        min={0}
        max={1}
        onChange={onSliderChange}
        marks={SliderConfidenceMarks}
        disabled={!updated}
      />
    </div>
  );
};

SliderConfidence.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

SliderConfidence.defaultProps = {
  updated: null,
};

export default SliderConfidence;

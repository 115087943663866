import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, DialogActions, DialogContent, Stack, Checkbox, Grid } from "@mui/material";
import Button from "components/MaterialKit/MKButton";
import Typography from "components/MaterialKit/MKTypography";
import Input from "components/MaterialKit/MKInput";

import expert from "assets/images/expert.jpg";
import LabelText from "../styles/LabelText";

const DialogInterpretation = ({
  myExpertise,
  answerQuestions,
  publicEngagement,
  confidence,
  note,
  otherSide,
  solution,
  wouldChange,
  haveChanged,
  handleDialogClose,
}) => {
  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogContent style={{ padding: "30px", paddingTop: "20px", paddingBottom: "20px" }}>
        <>
          {confidence === 2 && (
            <Grid
              container
              alignItems="center"
              spacing={10}
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Grid item xs={1}>
                <img src={expert} style={{ width: "55px" }} alt="..." />
              </Grid>
              <Grid item xs>
                <Typography variant="caption" style={{ fontSize: "18px" }}>
                  With friends and family, I am comfortable leading discussions on this topic and
                  offering advice.
                </Typography>
              </Grid>
            </Grid>
          )}
          {confidence === 3 && (
            <Grid
              container
              alignItems="center"
              spacing={10}
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Grid item xs={1}>
                <img src={expert} style={{ width: "55px" }} alt="..." />
              </Grid>
              <Grid item xs>
                <Typography variant="caption" style={{ fontSize: "18px" }}>
                  While not an expert (yet), I’ve deeply immersed myself in this topic and could
                  make valuable contributions to a community of knowledgeable individuals.
                </Typography>
              </Grid>
            </Grid>
          )}
          {confidence === 4 && (
            <Grid
              container
              alignItems="center"
              spacing={10}
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Grid item xs={1}>
                <img src={expert} style={{ width: "55px" }} alt="..." />
              </Grid>
              <Grid item xs>
                <Typography variant="caption" style={{ fontSize: "18px" }}>
                  I believe I could deliver an insightful and nuanced presentation on this topic to
                  an expert community, navigate this topic’s complexities, and confidently answer
                  challenging questions. In a room of experts, I can hold my own.
                </Typography>
              </Grid>
            </Grid>
          )}

          <Input
            style={{ marginTop: "20px" }}
            disabled
            type="note"
            label="Reflections"
            fullWidth
            multiline
            variant="outlined"
            value={note}
          />

          {otherSide && otherSide.length > 0 && (
            <Input
              style={{ marginTop: "40px" }}
              disabled
              type="otherSide"
              label="The strongest and most valid point(s) of other perspectives that differ significantly from my own..."
              fullWidth
              multiline
              variant="outlined"
              value={otherSide}
            />
          )}

          {solution && solution.length > 0 && (
            <Input
              style={{ marginTop: "40px" }}
              disabled
              type="solution"
              label="My recommended solution to rise above the polarization surrounding this issue..."
              fullWidth
              multiline
              variant="outlined"
              value={solution}
            />
          )}

          {wouldChange && wouldChange.length > 0 && (
            <Input
              style={{ marginTop: "40px" }}
              disabled
              type="wouldChange"
              label="I would change my position on this topic if..."
              fullWidth
              multiline
              variant="outlined"
              value={wouldChange}
            />
          )}

          {haveChanged && haveChanged.length > 0 && (
            <Input
              style={{ marginTop: "40px" }}
              disabled
              type="haveChanged"
              label="I changed my position on this topic when..."
              fullWidth
              multiline
              variant="outlined"
              value={haveChanged}
            />
          )}

          {myExpertise && myExpertise.length > 0 && (
            <Input
              style={{ marginTop: "40px" }}
              disabled
              type="myExpertise"
              label="My relevant expertise (self-declaration)"
              fullWidth
              multiline
              variant="outlined"
              value={myExpertise}
            />
          )}

          {answerQuestions && (
            <LabelText style={{ paddingTop: "40px" }}>
              <Checkbox disabled checked={answerQuestions} />
              Would be willing to answer questions from other members in our inSight community
            </LabelText>
          )}
          {answerQuestions && (
            <Input
              disabled
              type="question"
              label="Post your question here..."
              fullWidth
              multiline
              variant="outlined"
              value="This feature is disabled (still under development)..."
            />
          )}

          {publicEngagement && (
            <LabelText style={{ paddingTop: "20px" }}>
              <Checkbox disabled checked={publicEngagement} />
              Would be open to engaging in a face-to-face panel discussion
            </LabelText>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Stack
          flexWrap="wrap"
          justifyContent="center"
          flexDirection="row"
          width={1.0}
          style={{ marginBottom: "10px" }}
        >
          <Button onClick={() => handleDialogClose()} color="primary" circular>
            OK
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

DialogInterpretation.propTypes = {
  myExpertise: PropTypes.string,
  otherSide: PropTypes.string,
  solution: PropTypes.string,
  wouldChange: PropTypes.string,
  haveChanged: PropTypes.string,
  answerQuestions: PropTypes.bool,
  publicEngagement: PropTypes.bool,
  note: PropTypes.string.isRequired,
  confidence: PropTypes.number.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
};

DialogInterpretation.defaultProps = {
  myExpertise: "",
  otherSide: "",
  solution: "",
  wouldChange: "",
  haveChanged: "",
  answerQuestions: false,
  publicEngagement: false,
};

export default DialogInterpretation;

import React from "react";
import PropTypes from "prop-types";

// MUI
import { Paper, Container } from "@mui/material";
import Typography from "components/MaterialKit/MKTypography";

import QuestionText from "../styles/QuestionText";
import IdeaDetail from "../idea/IdeaDetail";
import NavbarTop from "../navbar/NavbarTop";
import ViewElephant101 from "./ViewElephant101";
import ViewElephant102 from "./ViewElephant102";

// <QuestionText>#{e.order}: Instructions</QuestionText>

const View = ({ activitiesDone }) => {
  return (
    <>
      <NavbarTop transparent />
      <Container maxWidth="sm" sx={{ mb: 8, mt: { xs: 2, sm: 5 } }}>
        {activitiesDone &&
          activitiesDone.length > 0 &&
          activitiesDone.map((e) => (
            <Paper key={e._id} style={{ padding: "15px", marginTop: "15px" }}>
              {e.selection === 1 && (
                <>
                  <QuestionText>#{e.order}: Add new inTell</QuestionText>
                </>
              )}

              {e.selection === 13 && (
                <>
                  <QuestionText>#{e.order}: Add key point summary of quote</QuestionText>
                  <div style={{ marginTop: "10px" }}>
                    <IdeaDetail
                      idea={e.inTellExisting}
                      hrefHiddenInternal
                      hrefHiddenExternal
                      greyOut
                      boldOutline
                    />
                  </div>
                </>
              )}

              {e.selection === 100 && (
                <>
                  <div style={{ marginTop: "10px" }}>
                    <IdeaDetail
                      instructions
                      idea={e.inTellExisting}
                      hrefHiddenInternal
                      hrefHiddenExternal
                      greyOut
                      showExtended
                      boldOutline
                    />
                  </div>
                </>
              )}

              {e.selection === 101 && (
                <>
                  <QuestionText>#{e.order}: Interpret guiding question</QuestionText>
                  <div style={{ marginTop: "10px" }}>
                    <IdeaDetail
                      communityTopic
                      showExtended
                      idea={e.communityTopic}
                      hrefHiddenInternal
                      hrefHiddenExternal
                      greyOut
                      boldOutline
                    />
                    <ViewElephant101 inTellExisting={e.communityTopic} />
                  </div>
                </>
              )}

              {e.selection === 102 && (
                <>
                  <QuestionText>#{e.order}: Interpret inTell</QuestionText>
                  <div style={{ marginTop: "10px" }}>
                    <IdeaDetail idea={e.inTellExisting} hrefHiddenInternal greyOut boldOutline />
                  </div>
                  <ViewElephant102 inTellExisting={e.inTellExisting} />
                </>
              )}

              {e.selection === 201 && (
                <>
                  <QuestionText style={{ marginBottom: "15px" }}>
                    #{e.order}: Interconnect inTell to guiding question
                  </QuestionText>

                  <Typography style={{ fontSize: "10px" }}>Community topic</Typography>
                  <div style={{ marginBottom: "5x", marginTop: "10px" }}>
                    <IdeaDetail
                      communityTopic
                      idea={e.inTellExisting}
                      hrefHiddenInternal
                      hrefHiddenExternal
                      greyOut
                      boldOutline
                    />
                  </div>

                  <Typography style={{ marginTop: "30px", fontSize: "10px" }}>inTell</Typography>
                  <div style={{ marginTop: "10px" }}>
                    <IdeaDetail
                      idea={e.communityTopic}
                      hrefHiddenInternal
                      hrefHiddenExternal
                      greyOut
                      boldOutline
                    />
                  </div>
                </>
              )}
            </Paper>
          ))}
      </Container>
    </>
  );
};

View.propTypes = {
  activitiesDone: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};

export default View;

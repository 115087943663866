import React from "react";
import PropTypes from "prop-types";

// MUI
import Box from "components/MaterialKit/MKBox";
import { Radio } from "@mui/material";

import LabelText from "../styles/LabelText";

// radioStatus: -2 = Undefined, -1 = No, 1 = Yes, 0 = Not Sure
const YesNoRadio = ({ radioStatus, setRadioStatus, showNotSure }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <div style={{ textAlign: "left" }}>
        <div style={{ marginBottom: "7px" }}>
          <LabelText>
            <Radio
              checked={radioStatus === 1}
              onClick={() => setRadioStatus(1)}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            />
            Yes
          </LabelText>
        </div>

        <div style={{ marginBottom: "7px" }}>
          <LabelText>
            <Radio
              checked={radioStatus === -1}
              onClick={() => setRadioStatus(-1)}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            />
            No
          </LabelText>
        </div>

        {showNotSure && (
          <div style={{ marginBottom: "7px" }}>
            <LabelText>
              <Radio
                checked={radioStatus === 0}
                onClick={() => setRadioStatus(0)}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              />
              Not Sure
            </LabelText>
          </div>
        )}
      </div>
    </Box>
  );
};

YesNoRadio.propTypes = {
  radioStatus: PropTypes.number.isRequired,
  setRadioStatus: PropTypes.func.isRequired,
  showNotSure: PropTypes.bool,
};

YesNoRadio.defaultProps = {
  showNotSure: false,
};

export default YesNoRadio;
